import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import info from './modules/info';
import session from './modules/session';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const state = {
  loadingStack: [],
  snackbarText: '',
  error: null,
  infoDialog: null
};

const mutations = {
  START_LOADING (state) {
    state.loadingStack.push(1);
  },
  STOP_LOADING (state, payload = {}) {
    if (payload.force) {
      state.loadingStack = [];
    } else {
      state.loadingStack.pop();
    }
  },
  SET_SNACKBAR_TEXT (state, text) {
    state.snackbarText = text;
  },
  SET_ERROR (state, error) {
    state.error = error;
  },
  SHOW_INFO_DIALOG (state, payload) {
    state.infoDialog = payload;
  }
};

const getters = {
  isLoading (state) {
    return state.loadingStack && state.loadingStack.length > 0;
  }
};

export default new Vuex.Store({
  modules: {
    auth,
    info,
    session
  },
  state,
  mutations,
  getters,
  strict: debug // https://vuex.vuejs.org/guide/strict.html
});
