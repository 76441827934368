import Cookie from 'js-cookie';
import { reloadWindow } from '../common/lang';

export const INTERCEPT_LOADING = 'interceptLoading';
export const INTERCEPT_COOKIES = 'interceptCookies';
export const INTERCEPT_UNAUTHORIZED = 'interceptUnauthorized';

export const loadingRequest = (config = { store: null }) => [
  request => {
    const store = config.store;
    if (interceptLoading(request) && store) {
      store.commit('START_LOADING');
    }
    return request;
  },
  error => {
    const store = config.store;
    if (interceptLoading(error.config) && store) {
      store.commit('START_LOADING');
    }
    return Promise.reject(error);
  }
];

export const loadingResponse = (config = { store: null }) => [
  response => {
    const store = config.store;
    if (interceptLoading(response.config) && store) {
      store.commit('STOP_LOADING');
    }
    return response;
  },
  error => {
    const store = config.store;
    if (interceptLoading(error.config) && store) {
      store.commit('STOP_LOADING');
    }
    return Promise.reject(error);
  }
];

export const unauthorizedResponse = () => [
  response => response,
  error => {
    if (interceptUnauthorized(error.config)) {
      if (error.response && error.response.status === 401) {
        reloadWindow();
      }
    }
    return Promise.reject(error);
  }
];

export const cookieResponse = (config = { store: null }) => [
  response => {
    const store = config.store;
    if (interceptCookies(response.config) && store) {
      store.commit('session/SET_SESSION_EXPIRY', Cookie.get('sessionExpiry'));
      store.commit('session/SET_SERVER_TIME', Cookie.get('serverTime'));
    }
    return response;
  },
  error => {
    const store = config.store;
    if (interceptCookies(error.config) && store) {
      store.commit('session/SET_SESSION_EXPIRY', Cookie.get('sessionExpiry'));
      store.commit('session/SET_SERVER_TIME', Cookie.get('serverTime'));
    }
    return Promise.reject(error);
  }
];

function interceptLoading (config = {}) {
  return isHandlerEnabled(INTERCEPT_LOADING, config);
}

function interceptUnauthorized (config = {}) {
  return isHandlerEnabled(INTERCEPT_UNAUTHORIZED, config);
}

function interceptCookies (config = {}) {
  return isHandlerEnabled(INTERCEPT_COOKIES, config);
}

function isHandlerEnabled (key, config = {}, defaultValue = true) {
  return config.hasOwnProperty(key) ? config[key] : defaultValue;
}
