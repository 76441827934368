import { InfoRepository } from '../../repositories/info-repository';

const state = {
  messages: []
};

const mutations = {
  SET_MESSAGES (state, newValues) {
    state.messages = newValues || [];
  },
  DELETE_MESSAGE (state, item) {
    let messagesNEW = [];
    for (let i = 0; i < state.messages.length; i++) {
      if (state.messages[i].id !== item.id) {
        messagesNEW.push(state.messages[i]);
      }
    }
    state.messages = messagesNEW;
  },
  ADD_MESSAGE (state, item) {
    state.messages.push(item);
  }
};

const actions = {
  init ({dispatch}) {
    return dispatch('findAll');
  },

  async findAll ({commit, state}) {
    try {
      const {data} = await InfoRepository.findAll();
      if (data) {
        commit('SET_MESSAGES', data);
      }
      return state.messages;
    } catch (error) {
      commit('SET_MESSAGES', null);
      throw error;
    }
  },

  async save ({commit, state}, payload) {
    try {
      const {data} = await InfoRepository.save(payload);
      commit('ADD_MESSAGE', data);
      return data;
    } catch (error) {
      commit('SET_ERROR', error, { root: true });
    }
  },

  async delete ({commit, state}, id) {
    try {
      const {data} = await InfoRepository.delete(id);
      commit('DELETE_MESSAGE', data);
      return data;
    } catch (error) {
      commit('SET_ERROR', error, { root: true });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
