<template>
  <v-row no-gutters align-content="end">
    <v-col>
      <nav class="d-flex">
        <template v-if="collapseThemeNavigation">
          <ul class="nav d-flex" v-if="visibleThemeWhenCollapsed">
            <li class="d-flex align-content-end flex-wrap">
              <router-link
                :active-class="visibleThemeWhenCollapsed.linkActiveClass"
                :class="visibleThemeWhenCollapsed.linkClass"
                :exact="!!visibleThemeWhenCollapsed.exact"
                :to="visibleThemeWhenCollapsed.to"
              >{{ visibleThemeWhenCollapsed.text }}
              </router-link>
            </li>
          </ul>
          <v-menu offset-y v-if="collapsedThemes.length > 0">
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on" class="ml-2 align-self-end">
                <v-icon size="24">mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group color="primary">
                <template v-for="theme in collapsedThemes">
                  <v-list-item color="primary" :key="`list-item-${theme.name}`" :to="theme.to">
                    <v-list-item-title>{{theme.text}}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
        <template v-else>
          <ul class="nav d-flex">
            <template v-for="theme in visibleThemeNavigation">
              <li class="d-flex align-content-end flex-wrap" :key="`list-item-${theme.name}`">
                <router-link
                  :active-class="theme.linkActiveClass"
                  :class="theme.linkClass"
                  :exact="!!theme.exact"
                  :to="theme.to"
                >{{ theme.text }}
                </router-link>
              </li>
            </template>
          </ul>
        </template>
        <v-spacer></v-spacer>
        <template v-if="collapseTechNavigation">
          <v-menu offset-y v-if="visibleTech.length > 0">
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on" class="mr-2 align-self-end">
                <v-icon size="24">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group color="primary">
                <template v-for="item in visibleTech.slice().reverse()">
                  <v-list-item
                    v-if="item.visible"
                    color="primary"
                    :key="`list-item-${item.name}`"
                    :href="item.native ? item.to : undefined"
                    :to="!item.native ? item.to : undefined"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
        <template v-else>
          <ul class="nav d-flex" v-if="visibleTech.length > 0">
            <template v-for="item in visibleTech">
              <li class="d-flex align-content-end flex-wrap" :key="`list-item-${item.name}`">
                <router-link
                  v-if="!item.native"
                  :active-class="item.linkActiveClass"
                  :class="item.linkClass"
                  :exact="!!item.exact"
                  :to="item.to"
                >{{ item.text }}
                </router-link>
                <a v-else :href="item.to" :class="item.linkClass">{{ item.text }}</a>
              </li>
            </template>
          </ul>
        </template>
      </nav>
    </v-col>
  </v-row>
</template>

<script>
import _get from 'lodash/get';

import { API } from '../../config';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('auth', ['currentUser']),

    routes () {
      return this.$router.options.routes;
    },

    visibleThemeWhenCollapsed () {
      const currentRoute = this.$route;
      const currentTheme = this.themeNavigation.find(
        route => route.name === currentRoute.name
      );

      return currentTheme
        ? currentTheme
        : this.themeNavigation.length > 0 && this.themeNavigation[0].visible
          ? this.themeNavigation[0]
          : undefined;
    },

    collapsedThemes () {
      return this.visibleThemeNavigation.filter(item => {
        if (this.visibleThemeWhenCollapsed) {
          return item.name !== this.visibleThemeWhenCollapsed.name;
        } else {
          return true;
        }
      });
    },

    collapseThemeNavigation () {
      return this.$vuetify.breakpoint.xs;
    },

    visibleThemeNavigation () {
      return this.themeNavigation.filter(item => item.visible);
    },

    themeNavigation () {
      return [
        {
          name: 'about'
        },
        {
          name: 'karte'
        },
        {
          name: 'stichprobeAll',
          text: 'Stichprobe'
        },
        {
          name: 'auskunftErstellen',
          text: 'Auskunft'
        }
      ].map(theme => {
        const route = this.findRouteByName(theme.name);
        return {
          name: theme.name,
          to: route.path,
          text: theme.text || _get(route, 'meta.title', []),
          linkClass: 'with-background',
          linkActiveClass: 'active-with-background',
          visible: this.isRouteVisible(route)
        };
      });
    },

    collapseTechNavigation () {
      return this.$vuetify.breakpoint.xs;
    },

    visibleTech () {
      return this.techNavigation.filter(item => item.visible);
    },

    techNavigation () {
      return [
        {
          name: 'admin',
          text: "Admin"
        },
        {
          name: 'impressum'
        },
        {
          name: 'privacy'
        },
        {
          name: 'accessibility'
        },
        {
          name: 'login',
          exact: true,
          text: 'Login',
          visible: !this.currentUser
        },
        {
          name: 'logout',
          native: true,
          to: `${API.logout.url}?${API.logout.targetUrlParameter}=${window.location.origin}${this.$router.options.base}`,
          text: 'Logout',
          visible: !!this.currentUser
        }
      ].map(theme => {
        const route = this.findRouteByName(theme.name);
        return {
          name: theme.name,
          exact: theme.exact,
          to: theme.to || route.path,
          text: theme.text || _get(route, 'meta.title', []),
          linkClass: 'no-background',
          linkActiveClass: 'active-no-background',
          native: theme.native,
          visible:
            theme.visible !== undefined
              ? theme.visible
              : this.isRouteVisible(route)
        };
      });
    }
  },
  methods: {
    findRouteByName (name) {
      return this.routes.find(route => route.name === name);
    },
    isRouteVisible (route) {
      const authRequired = _get(route, 'meta.authRequired', false);
      const requiredRoles = _get(route, 'meta.roles', []);
      return (
        !authRequired ||
        (this.currentUser && this.currentUser.hasAnyRole(requiredRoles))
      );
    }
  }
};
</script>

<style scoped>
ul.nav {
  list-style-type: none;
  padding-left: 0;
}

.nav a {
  text-decoration: none;
  display: inline-block;
}

.with-background {
  border: 1px solid #C9C9C9;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  color: black;
  padding: 5px 10px 3px;
  background-color: #F1F1F1;
  font-size: 13px;
}

.active-with-background {
  padding: 10px 10px 3px;
  font-weight: bold;
}

.active-with-background,
.with-background:hover {
  background-color: #3C78B5;
  color: white;
}

.no-background {
  color: #707070;
  font-weight: bold;
  padding: 5px 10px 3px;
}

.active-no-background {
  border-radius: 4px 4px 0 0;
  background-color: #3C78B5;
  color: white;
  font-weight: bold;
}
</style>
