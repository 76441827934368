import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import de from 'vuetify/es5/locale/de';
import VIconNRW from '../components/VIconNRW';

Vue.use(Vuetify);

const VOKAR_ICONS = {
  nrw: {
    component: VIconNRW,
  }
};

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: VOKAR_ICONS
  },
  theme: {
    themes: {
      light: {
        primary: '#3C78B5',
        error: colors.red.accent4
      }
    }
  },
  lang: {
    locales: { de },
    current: 'de',
  }
});
