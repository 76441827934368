import { currentTime, reloadWindow } from '../../common/lang';

const HEARTBEAT = 10 * 1000;
const SHOW_DIALOG = 60 * 1000;

const state = {
  serverTimeOffset: null,
  sessionExpiry: null,
  timeoutDialog: false
};

const mutations = {
  SET_SESSION_EXPIRY (state, newValue) {
    state.sessionExpiry = newValue ? parseInt(newValue) : null;
  },
  SET_SERVER_TIME (state, newValue) {
    const serverTime = newValue ? parseInt(newValue) : null;
    state.serverTimeOffset = serverTime
      ? currentTime() - serverTime
      : null;
  },
  SHOW_TIMEOUT_DIALOG (state) {
    state.timeoutDialog = true;
  },
  HIDE_TIMEOUT_DIALOG (state) {
    state.timeoutDialog = false;
  }
};

const actions = {
  init ({ dispatch }) {
    dispatch('keepWatch');
  },

  keepWatch ({ dispatch, commit, state, rootState }) {
    if (rootState.auth.currentUser) {
      const now = currentTime();

      if (state.sessionExpiry && state.serverTimeOffset) {
        if (now - state.serverTimeOffset >= state.sessionExpiry - SHOW_DIALOG) {
          if (!state.timeoutDialog) {
            commit('SHOW_TIMEOUT_DIALOG');
          }
        }

        if (now - state.serverTimeOffset >= state.sessionExpiry) {
          if (state.timeoutDialog) {
            commit('HIDE_TIMEOUT_DIALOG');
          }
          reloadWindow();
        }
      }
    }

    let handler = setTimeout(() => {
      clearTimeout(handler);
      dispatch('keepWatch');
    }, HEARTBEAT);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
