import { API } from '../config';
import axios from 'axios';
import { cookieResponse, loadingRequest, loadingResponse, unauthorizedResponse } from './axios-interceptors';

const baseURL = `${API.url}/api/v1`;
const config = {
  baseURL,
  withCredentials: true
};

const repository = axios.create(config);

export const DefaultRepository = {
  getInstance: () => repository,
  init: (config = {}) => {
    [
      loadingRequest(config)
    ].forEach(interceptor => repository.interceptors.request.use(...interceptor));

    [
      loadingResponse(config),
      unauthorizedResponse(config),
      cookieResponse(config)
    ].forEach(interceptor => repository.interceptors.response.use(...interceptor));
  }
};
