<template>
  <v-btn
    v-on="$listeners"
    v-bind="{...$props, ...$attrs}"
    :block="$vuetify.breakpoint.xs"
  >
    <slot></slot>
  </v-btn>
</template>
<script>
export default {};
</script>
