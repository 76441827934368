<template>
  <v-footer
    v-bind="$attrs"
    class="d-flex justify-space-between grey--text text--darken-2"
  >
    <div
      class="text-caption font-italic"
    >
      Stand: {{ APP_BUILD_DATE }}<br/>
      Version: {{ APP_VERSION }}
    </div>

    <div class="text-caption d-flex align-center font-weight-bold">
      <v-icon small class="mr-1">mdi-email-outline</v-icon>
      <a href="mailto:vokar-gs@lanuv.nrw.de" class="grey--text text--darken-2">VOKAR-GS</a>
    </div>

    <div
      class="text-caption text-right font-italic"
    >
      &copy; Information und Technik<br/>
      Nordrhein-Westfalen (IT.NRW)
    </div>
  </v-footer>
</template>

<script>
import { APP_VERSION, APP_BUILD_DATE } from '../../config';

export default {
  data () {
    return {
      APP_VERSION,
      APP_BUILD_DATE
    };
  }
};
</script>

<style scoped>
>>> a {
  text-decoration: none;
}

>>> a:hover {
  text-decoration: underline;
}
</style>
