<template>
  <VDialog
    v-model="show"
    v-bind="$attrs"
  >
    <VDialogContent
      :accent="accent"
      :color="color"
      :icon="icon"
      :title="title"
    >
      {{ text }}
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey lighten-3"
          depressed
          tile
          @click="show = false"
        >
          Schlie&szlig;en
        </v-btn>
      </template>
    </VDialogContent>
  </VDialog>
</template>

<script>
import VDialog from '../VDialog';
import VDialogContent from '../VDialogContent';
import { mapState } from 'vuex';

export default {
  components: {
    VDialog,
    VDialogContent
  },
  data () {
    return {
      show: false,
      title: '',
      icon: '',
      color: '',
      accent: '',
      text: ''
    };
  },
  computed: {
    ...mapState([
      'infoDialog'
    ])
  },
  watch: {
    infoDialog (info) {
      if (info) {
        this.title = info.title;
        this.icon = info.icon;
        this.color = info.color;
        this.accent = info.accent;
        this.text = info.text;
        this.show = true;
        this.$store.commit('SHOW_INFO_DIALOG', null);
      }
    }
  }
};
</script>

