<template>
  <v-row
    no-gutters
    class="farbleiste primary"
  >
    <v-spacer></v-spacer>
    <img
      src="@/assets/farbleiste.gif"
      alt="Farbleiste: blau =Umwelt, grün =Naturschutz, orange =Verbraucherschutz, dunkelorange =Landwirtschaft"
    />
  </v-row>
</template>

<script>
export default {};
</script>
