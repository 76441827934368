// Core properties
export const APP_VERSION = process.env.VUE_APP_VERSION;
export const APP_BUILD_DATE = process.env.VUE_APP_BUILD_DATE;

// Role properties
export const ROLE_LNATSCHG = "74_LNATSCHG";
export const ROLE_BNOTK = "BNOTK";
export const ROLE_LV_FACHBETREUER = "VOKAR_NRW:Fachbetreuer";
export const ROLE_LV_BEZIRKSREGIERUNG = "VOKAR_NRW:Bezirksregierung";
export const ROLE_ADMIN = "ADMIN";

const baseApiUrl = process.env.NODE_ENV == 'development'
  ? process.env.VUE_APP_GLOBAL_VOKAR_API_URL
  : '';

// Api properties
export const API = Object.freeze({
  url: baseApiUrl,
  login: {
    url: `${baseApiUrl}/api/login/safe`,
    targetUrlParameter: 'RelayState'
  },
  logout: {
    url: `${baseApiUrl}/api/logout`,
    targetUrlParameter: 'RelayState'
  }
});

// Esri ArcGIS JS Api properties
export const ESRI_API = Object.freeze({
  url: process.env.VUE_APP_GLOBAL_VOKAR_ESRI_URL,
  css: `${process.env.VUE_APP_GLOBAL_VOKAR_ESRI_URL}/esri/themes/light/main.css`
});

// Map properties
export const MAP = Object.freeze({
  initExtent: {
    xmin: 289473,
    ymin: 5579665,
    xmax: 530530,
    ymax: 5818409,
    spatialReference: { wkid: 25832 }
  },
  lods: [
    {
      'level': 0,
      'resolution': 611.4962262814088,
      'scale': 2311162.21715511
    },
    {
      'level': 1,
      'resolution': 305.748113140703,
      'scale': 1155581.10857755
    },
    {
      'level': 2,
      'resolution': 152.87405657035234,
      'scale': 577790.554288778
    },
    {
      'level': 3,
      'resolution': 76.43702828517617,
      'scale': 288895.277144389
    },
    {
      'level': 4,
      'resolution': 38.21851414258821,
      'scale': 144447.638572195
    },
    {
      'level': 5,
      'resolution': 19.109257071294056,
      'scale': 72223.8192860973
    },
    {
      'level': 6,
      'resolution': 9.554628535647014,
      'scale': 36111.9096430486
    },
    {
      'level': 7,
      'resolution': 4.777314267823507,
      'scale': 18055.9548215243
    },
    {
      'level': 8,
      'resolution': 2.388657133911756,
      'scale': 9027.97741076216
    },
    {
      'level': 9,
      'resolution': 1.194328566955878,
      'scale': 4513.98870538108
    },
    {
      'level': 10,
      'resolution': 0.597164283477939,
      'scale': 2256.99435269054
    },
    {
      'level': 11,
      'resolution': 0.2985821417389695,
      'scale': 1128.49717634527
    }
  ]
});

// Graphic properties
export const GRAPHICS = Object.freeze({
  [ROLE_LNATSCHG]: {
    type: 'simple-fill',
    color: [0, 0, 0, 0],
    outline: { type: 'simple-line', color: [0, 255, 0], width: 2 }
  },
  vokar: {
    type: 'simple-fill',
    color: [0, 0, 0, 0],
    outline: { type: 'simple-line', color: [255, 0, 0], width: 2 }
  },
  default: {
    type: 'simple-fill',
    color: [0, 0, 0, 0],
    outline: { type: 'simple-line', color: [0, 0, 0], width: 2 }
  }
});

// Job properties
export const JOB_SUCCEEDED = 'SUCCEEDED';
export const JOB_SUBMITTED = 'SUBMITTED';
export const JOB_EXECUTING = 'EXECUTING';
