<template>
  <VDialog
    v-model="show"
    v-bind="$attrs"
  >
    <VDialogContent
      title="Es ist ein Fehler aufgetreten"
      icon="mdi-close-circle"
      color="error"
    >
      <p>{{ message }}</p>
      <v-subheader v-if="fieldErrors && fieldErrors.length > 0 || requestDetails || stack">Details</v-subheader>
      <v-expansion-panels accordion multiple>
        <v-expansion-panel v-if="fieldErrors && fieldErrors.length > 0">
          <v-expansion-panel-header>Validierungsfehler</v-expansion-panel-header>
          <v-expansion-panel-content>
            <ul>
              <li
                v-for="fieldError in fieldErrors"
                :key="fieldError.field"
              >
                {{ fieldError.field }}: {{ fieldError.message }}
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="requestDetails">
          <v-expansion-panel-header>Request</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table>
              <tbody>
              <tr>
                <td>URL</td>
                <td>{{ requestDetails.url }}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{{ requestDetails.status }}</td>
              </tr>
              <tr>
                <td>Parameter</td>
                <td>{{ requestDetails.params }}</td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="stack">
          <v-expansion-panel-header>Stack</v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ stack }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey lighten-3"
          depressed
          tile
          @click="show = false"
        >
          Schlie&szlig;en
        </v-btn>
      </template>
    </VDialogContent>
  </VDialog>
</template>

<script>
import VDialog from '../VDialog';
import VDialogContent from '../VDialogContent';

import _get from 'lodash/get';
import _has from 'lodash/has';

import { mapState } from 'vuex';

export default {
  components: {
    VDialog,
    VDialogContent
  },
  data () {
    return {
      show: false,
      message: '',
      fieldErrors: null,
      stack: null,
      requestDetails: null,
    };
  },
  computed: {
    ...mapState([
      'error'
    ])
  },
  methods: {},
  watch: {
    error (error) {
      if (error) {
        this.reset();
        if (error.isAxiosError) {
          this.handleAxiosError(error);
        } else if (error.declaredClass) {
          this.handleEsriError(error);
        } else {
          this.message = error.toString();
          this.stack = error.stack;
        }
        this.show = true;
        this.$store.commit('SET_ERROR', null);
      }
    }
  },
  methods: {
    handleAxiosError (error) {
      if (_has(error, 'response.data.message')) {
        this.message = error.response.data.message;
      } else if (_has(error, 'response.data.fieldErrors')) {
        this.message = 'Fehler beim Validieren der Angaben.';
        this.fieldErrors = error.response.data.fieldErrors;
      } else {
        this.message = error.toString();
      }

      this.requestDetails = {
        url: _get(error, 'config.url', ''),
        status: _get(error, 'response.status', ''),
        params: _has(error, 'config.params')
          ? error.config.params
          : _get(error, 'config.data', '')
      };
      this.stack = error.stack;
    },
    handleEsriError (error) {
      if (error.declaredClass === 'esri.tasks.support.JobInfo') {
        this.message = `${error.declaredClass}: ${error.jobStatus}`;
        this.requestDetails = {
          params: `Job-Id: ${error.jobId}`
        }
      } else {
        this.message = 'ESRI ArcGIS Api: Unbekannter Fehler.';
      }
    },
    reset () {
      this.message = '';
      this.fieldErrors = null;
      this.stack = null;
      this.requestDetails = null;
    }
  }
};
</script>
