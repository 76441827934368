<template>
  <v-app>
    <TheAppBar
      app
    />


    <v-main>
      <div class="d-flex flex-column fill-height">
        <TheBanner/>
        <div class="flex-grow-1 position-relative">
          <router-view></router-view>
        </div>
      </div>
    </v-main>
    <TheFooter
      v-if="!$vuetify.breakpoint.xs"
      app
      fixed
    />
    <TheSnackbar/>
    <TheInfoDialog
      max-width="600px"
    />
    <TheRequestErrorDialog
      max-width="600px"
    />
    <TheSessionTimeoutDialog
      max-width="600px"
    />
  </v-app>
</template>

<script>
import TheAppBar from './components/TheAppBar';
import TheBanner from './components/TheBanner';
import TheInfoDialog from './components/TheInfoDialog';
import TheRequestErrorDialog from './components/TheRequestErrorDialog';
import TheFooter from './components/TheFooter';
import TheSnackbar from './components/TheSnackbar';
import TheSessionTimeoutDialog from './components/TheSessionTimeoutDialog';

export default {
  name: 'App',
  components: {
    TheAppBar,
    TheBanner,
    TheInfoDialog,
    TheRequestErrorDialog,
    TheFooter,
    TheSnackbar,
    TheSessionTimeoutDialog
  },
  watch: {
    '$route' (to) {
      const title = 'VOKAR NRW';
      document.title = to.meta.title ? `${title} - ${to.meta.title}` : 'VOKAR NRW';
    }
  },
  mounted () {
    this.$store.dispatch('info/init');
  }
};
</script>

<style>
.theme--light.v-application,
.theme--light.v-toolbar,
.theme--light.v-footer {
  background-color: rgba(255, 255, 255, 1) !important;
}

.theme--light.v-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.theme--light.v-data-table thead tr th {
  color: black !important;
}

.text-underline {
  text-decoration: underline;
}

.position-relative {
  position: relative;
}

.white-space-pre {
  white-space: pre;
}
</style>
