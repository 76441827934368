import { DefaultRepository } from './default-repository';

const resource = '/stichprobe';
const repository = DefaultRepository.getInstance();

export const StichprobeRepository = {
  search (query, config = {}) {
    return repository.get(resource, {
      params: query,
      ...config
    });
  },
  findById (id, config = {}) {
    return repository.get(`${resource}/${id}`, config);
  },
  patch (id, payload, config = {}) {
    return repository.patch(`${resource}/${id}`, payload, config);
  }
};
