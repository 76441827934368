<template>
  <div class="v-dialog-content elevation-1">
    <div :class="titleClasses">
      <h2>{{ title }}</h2>
    </div>
    <div class="pa-4">
      <slot></slot>
    </div>
    <VActionRow class="pa-2">
      <slot name="actions"></slot>
    </VActionRow>
  </div>
</template>

<script>
import VActionRow from '../VActionRow';

export default {
  components: {
    VActionRow
  },
  props: {
    title: {
      type: String,
      required: false,
      default: undefined
    },
    color: {
      type: String,
      required: false,
      default: 'primary'
    },
    accent: {
      type: String,
      required: false,
      default: undefined
    }
  },
  computed: {
    titleClasses () {
      return [
        'v-dialog-content__title',
        this.color,
        this.accent
      ].filter(Boolean);
    }
  }
};
</script>

<style>
.v-dialog-content {
  background-color: white;
}

.v-dialog-content__title {
  padding: 5px 10px;
}

.v-dialog-content__title h2 {
  color: white;
  font-size: 13px;
  font-weight: bold;
}
</style>
