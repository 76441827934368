import { createUser } from '@/model/User';
import { AuthRepository } from '../../repositories/auth-repository';
import { INTERCEPT_UNAUTHORIZED, INTERCEPT_LOADING } from '../../repositories/axios-interceptors';

const state = {
  currentUser: null
};

const mutations = {
  SET_CURRENT_USER (state, newValue) {
    state.currentUser = newValue
      ? createUser(newValue)
      : null;
  }
};

const getters = {
  isAuthenticated (state) {
    return !!state.currentUser;
  }
};

const actions = {
  init ({ dispatch }) {
    return dispatch('validate');
  },

  async validate ({ commit, state }) {
    try {
      const { data } = await AuthRepository.validate({
          [INTERCEPT_UNAUTHORIZED]: false,
          [INTERCEPT_LOADING]: false
        }
      );
      commit('SET_CURRENT_USER', data);
      return state.currentUser;
    } catch (error) {
      commit('SET_CURRENT_USER', null);
      throw error;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
