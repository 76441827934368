import moment from 'moment';

// thx! https://stackoverflow.com/a/41700401
export function parseTemplate (template, map, fallback) {
  function _get (path, obj, fb = `$\{${path}}`) {
    return path.split('.').reduce((res, key) => res[key] || fb, obj);
  }

  return template.replace(/\$\{.+?}/g, (match) => {
    const path = match.substr(2, match.length - 3).trim();
    return _get(path, map, fallback);
  });
}

export function formatDate (date, format = 'DD.MM.YYYY') {
  return date ? moment(date).format(format) : null;
}

export function formatDateTime (dateTime, format = 'DD.MM.YYYY HH:mm:ss') {
  return dateTime ? moment(dateTime).format(format) : null;
}

export function currentTime () {
  return new Date().getTime();
}

export function reloadWindow () {
  window.location.reload();
}

export function replaceLocation (url) {
  window.location.replace(url);
}

export function requiredRule (message) {
  return value => !!value || message;
}

// THX! https://stackoverflow.com/a/49541777
export function filterTree (root, filter, filterKey, itemChildren = "children") {
  const result = [];
  root.forEach(node => {
    let children;
    if (filter.includes(node[filterKey])) {
      result.push(node);
      return;
    }
    children = filterTree(node[itemChildren] || [], filter, filterKey, itemChildren);
    if (children.length) {
      result.push(Object.assign({}, node, {[itemChildren]: children}));
    }
  });
  return result;
}
