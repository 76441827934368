import store from '../store';
import { ROLE_ADMIN, ROLE_BNOTK, ROLE_LV_BEZIRKSREGIERUNG, ROLE_LV_FACHBETREUER } from '../config';
import { StichprobeRepository } from '../repositories/stichprobe-repository';

import _get from 'lodash/get';

export default [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    redirect: to => {
      return {
        name: 'login'
      };
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/Privacy.vue'),
    meta: {
      title: 'Datenschutz'
    }
  },
  {
    path: '/accessibility',
    name: 'accessibility',
    component: () => import('../views/Accessibility.vue'),
    meta: {
      title: 'Barrierefreiheit'
    }
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: () => import('../views/Impressum.vue'),
    meta: {
      title: 'Impressum'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue'),
    meta: {
      authRequired: true,
      roles: [ROLE_BNOTK, ROLE_LV_FACHBETREUER, ROLE_LV_BEZIRKSREGIERUNG],
      title: 'Über VOKAR NRW'
    },
    props: () => ({
      user: store.state.auth.currentUser
    })
  },
  {
    path: '/auskunft/erstellen',
    name: 'auskunftErstellen',
    component: () => import('../views/AuskunftErstellen'),
    meta: {
      authRequired: true,
      roles: [ROLE_BNOTK, ROLE_LV_FACHBETREUER],
      title: 'Auskunft erstellen'
    },
    props: (route) => ({
      user: store.state.auth.currentUser,
      auskunftId: _get(route, 'query.auskunftId', undefined)
    })
  },
  {
    path: '/auskunft/:vokarId',
    name: 'auskunftBearbeiten',
    meta: {
      authRequired: true,
      roles: [ROLE_BNOTK, ROLE_LV_FACHBETREUER, ROLE_LV_BEZIRKSREGIERUNG]
    },
    beforeEnter (to, from, next) {
      const auskunftId = to.params.vokarId.replace(/(VOKAR-)/i, '');
      const user = store.state.auth.currentUser;

      next({
        name: user.hasAnyRole([ROLE_BNOTK]) ? 'auskunftErstellen' : 'karte',
        query: {
          auskunftId
        }
      });
    }
  },
  {
    path: '/karte',
    name: 'karte',
    component: () => import('../views/Karte'),
    meta: {
      authRequired: true,
      roles: [ROLE_LV_FACHBETREUER, ROLE_LV_BEZIRKSREGIERUNG],
      title: 'Karte'
    },
    props: (route) => ({
      user: store.state.auth.currentUser,
      auskunftId: _get(route, 'query.auskunftId', undefined)
    })
  },
  {
    path: '/stichprobe',
    name: 'stichprobeAll',
    component: () => import('../views/StichprobeAll'),
    meta: {
      authRequired: true,
      roles: [ROLE_LV_FACHBETREUER],
      title: 'Alle Stichproben'
    },
    props: () => ({
      user: store.state.auth.currentUser
    })
  },
  {
    path: '/stichprobe/:id',
    name: 'stichprobeVerify',
    component: () => import('../views/StichprobeVerify'),
    meta: {
      authRequired: true,
      roles: [ROLE_LV_FACHBETREUER],
      title: 'Stichprobe überprüfen'
    },
    props: (route) => ({
      user: store.state.auth.currentUser,
      stichprobeId: route.params.id
    })
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/Admin'),
    meta: {
      authRequired: true,
      roles: [ROLE_ADMIN],
      title: 'Admin'
    },
    redirect: '/admin/session',
    children: [
      {
        path: 'session',
        component: () => import('../views/Session')
      },
      {
        path: 'news',
        component: () => import('../views/News')
      }
    ]
  },
  {
    path: '/not-found',
    name: 'notFound',
    component: () => import('../views/NotFound.vue'),
    props: true
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import('../views/Forbidden.vue')
  },
  {
    path: '*',
    redirect: {
      name: 'notFound'
    }
  }
];
