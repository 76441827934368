<template>
  <v-snackbar
    v-model="show"
    color="primary"
  >
    {{ text }}
    <v-btn
      dark
      text
      @click="show = false"
    >
      Schlie&szlig;en
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data () {
    return {
      show: false,
      text: ''
    };
  },
  computed: {
    ...mapState([
      'snackbarText'
    ])
  },
  watch: {
    snackbarText (text) {
      if (text) {
        this.text = this.snackbarText;
        this.show = true;
        this.$store.commit('SET_SNACKBAR_TEXT', '');
      }
    }
  }
};
</script>
