<template>
  <v-app-bar
    v-bind="$attrs"
    :height="height"
    :hide-on-scroll="$vuetify.breakpoint.smAndDown"
    elevation="0"
  >
    <v-container fluid class="pa-0">
      <TheProgressBar ref="progress" height="2px"/>
      <RowTitle ref="title" style="height: 70px" class="px-2 pt-1"/>
      <RowFarbleiste ref="farbleiste" style="height: 8px"/>
      <RowNavigation ref="navigation" style="height: 41px;"/>
      <v-divider></v-divider>
    </v-container>
  </v-app-bar>
</template>

<script>
import RowTitle from './RowTitle';
import RowFarbleiste from './RowFarbleiste';
import RowNavigation from './RowNavigation';
import TheProgressBar from '../TheProgressBar';

export default {
  components: {
    RowTitle,
    RowFarbleiste,
    RowNavigation,
    TheProgressBar
  },
  data () {
    return {
      height: null
    };
  },
  mounted () {
    this.height =
      this.$refs.progress.$el.clientHeight +
      this.$refs.title.$el.clientHeight +
      this.$refs.farbleiste.$el.clientHeight +
      this.$refs.navigation.$el.clientHeight;
  }
};
</script>

<style scoped>
>>> .v-toolbar__content {
  padding: 0;
}

>>> .v-divider {
  border-color: #C9C9C9 !important;
}
</style>
