<template>
  <div>
    <v-banner
      v-for="message in localMessages"
      v-model="visibleBanners[message.id]"
      :key="message.id"
      :single-line="!$vuetify.breakpoint.smAndDown"
      :value="message.id"
    >
      <v-icon
        slot="icon"
        color="primary"
      >
        mdi-information
      </v-icon>
      {{ message.text }}
      <template v-slot:actions="{ dismiss }">
        <v-btn
          text
          color="primary accent-4"
          @click="dismiss"
        >
          Schlie&szlig;en
        </v-btn>
      </template>
    </v-banner>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data () {
    return {
      localMessages: [],
      visibleBanners: {}
    };
  },
  computed: {
    ...mapState('info', [
      'messages'
    ]),
  },
  watch: {
    messages (values) {
      if (values) {
        this.visibleBanners = values.reduce((accumulator, value) => ({
          ...accumulator,
          [value.id]: true,
        }), {});
        this.localMessages = values;
      }
    }
  }
};
</script>
