<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
  >
    <VDialogContent
      title="Sitzung verlängern"
      icon="mdi-information"
      color="primary"
    >
      Ihre Sitzung läuft in einer Minute ab.
      <template #actions>
        <v-spacer></v-spacer>
        <VBtnResponsive
          depressed
          color="primary"
          @click="extendSession"
        >
          Sitzung verl&auml;ngern
        </VBtnResponsive>
      </template>
    </VDialogContent>
  </v-dialog>
</template>

<script>
import VBtnResponsive from '../VBtnResponsive';
import VDialogContent from '@/components/VDialogContent';

import { mapState } from 'vuex';
import { AuthRepository } from '../../repositories/auth-repository';

export default {
  components: {
    VBtnResponsive,
    VDialogContent
  },
  data () {
    return {
      show: false,
      text: ''
    };
  },
  computed: {
    ...mapState('session', [
      'timeoutDialog',
    ])
  },
  watch: {
    timeoutDialog (value) {
      if (this.show !== value) {
        this.show = value;
      }
    },
    show (show) {
      if (show !== this.timeoutDialog) {
        this.$store.commit(show ? 'session/SHOW_TIMEOUT_DIALOG' : 'session/HIDE_TIMEOUT_DIALOG');
      }
    }
  },
  methods: {
    extendSession () {
      AuthRepository.validate();
      this.show = false;
    }
  }
};
</script>
