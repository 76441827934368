import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import store from '../store';

import { ROLE_BNOTK, ROLE_LV_BEZIRKSREGIERUNG, ROLE_LV_FACHBETREUER } from '../config';

import _flatten from 'lodash/flatten';


Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
});

router.beforeEach(async (to, from, next) => {
  let validUser = store.getters['auth/isAuthenticated']
    ? await store.dispatch('auth/validate')
    : null;

  if (to.name === 'login' && validUser) {
    if (validUser.hasAnyRole([ROLE_LV_FACHBETREUER, ROLE_LV_BEZIRKSREGIERUNG])) {
      redirectToMap();
    } else if (validUser.hasAnyRole([ROLE_BNOTK])) {
      redirectToCreateAuskunft();
    } else {
      redirectToForbidden();
    }
  } else {
    const authRequired = to.matched.some((route) => route.meta.authRequired);
    if (!authRequired) {
      next();
    } else {
      if (validUser) {
        const requiredRoles = _flatten(to.matched.map((route) => route.meta.roles || []));
        if (validUser.hasAnyRole(requiredRoles)) {
          next();
        } else {
          redirectToForbidden();
        }
      } else {
        redirectToLogin();
      }
    }
  }

  function redirectToMap () {
    next({
      name: 'karte'
    });
  }

  function redirectToCreateAuskunft () {
    next({
      name: 'auskunftErstellen'
    });
  }

  function redirectToForbidden () {
    next({
      name: 'forbidden'
    });
  }

  function redirectToLogin () {
    next({
      name: 'login',
      query: {
        relayState: window.location.href
      }
    });
  }
});

export default router;
