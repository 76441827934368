<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  computed: {
    xs () {
      return this.$vuetify.breakpoint.xs;
    },
    classes () {
      return {
        'd-flex': true,
        'align-center': true,
        'flex-row': !this.xs,
        'flex-column': this.xs,
        'v-action-row': !this.xs,
        'v-action-row__mobile': this.xs
      };
    }
  }
};
</script>

<style scoped>
.v-action-row >>> .v-btn.v-btn + .v-btn {
  margin-left: 8px;
}

.v-action-row__mobile >>> .v-btn.v-btn + .v-btn {
  margin-top: 8px;
}
</style>
