import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import moment from 'moment';
import { DefaultRepository } from './repositories/default-repository';

moment.locale(window.navigator.userLanguage || window.navigator.language);

DefaultRepository.init({ store });

Vue.config.productionTip = false;

store.dispatch('session/init');
store.dispatch('auth/init')
  .catch(() => {
    /* ignore errors here */
  })
  .finally(() => {
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app');
  });
