<template>
  <v-dialog
    v-bind="{...$props, ...$attrs}"
    v-on="$listeners"
  >
    <slot></slot>
  </v-dialog>
</template>

<script>
export default {};
</script>

<style scoped>
>>> .v-dialog {
  border-radius: 0;
}
</style>
