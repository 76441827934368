<template>
  <v-progress-linear
    :indeterminate="isLoading"
    :background-opacity="0"
    :height="height"
  ></v-progress-linear>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    height: {}
  },
  computed: {
    ...mapGetters([
      'isLoading'
    ])
  }
};
</script>

