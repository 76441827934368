import _isEmpty from 'lodash/isEmpty';

class User {
  constructor (
    {
      id = '',
      name = null,
      vorname = null,
      nachname = null,
      kennziffer = null,
      email = null,
      bundesland = null,
      amtstaetigkeiten = [],
      rollen = []
    } = {}
  ) {
    this.id = id;
    this.name = name;
    this.vorname = vorname;
    this.nachname = nachname;
    this.email = email;
    this.bundesland = bundesland;
    this.kennziffer = kennziffer;
    this.rollen = rollen;
    this.amtstaetigkeiten = amtstaetigkeiten;
  }

  hasAnyRole (roles) {
    return _isEmpty(roles) ? true : roles.some(role => this.rollen.includes(role));
  }
}

export function createUser (data) {
  return Object.freeze(new User(data));
}
