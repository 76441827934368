import { DefaultRepository } from './default-repository';

const resource = '/me';
const repository = DefaultRepository.getInstance();

export const UserRepository = {
  me (config = {}) {
    return repository.get(resource, config);
  }
};
