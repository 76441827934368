import { DefaultRepository } from './default-repository';

const resource = '/news';
const repository = DefaultRepository.getInstance();

export const InfoRepository = {
  findAll (config = {}) {
    return repository.get(`${resource}`, config);
  },
  delete (id, config = {}) {
    return repository.delete(`${resource}/${id}`, config);
  },
  save (payload, config = {}) {
    return repository.post(resource, payload, config);
  }
};
